import router from './index'
import { useAuthStore } from '@/store/authStore';
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    if (authStore.token != null && authStore.token != '')
    {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }
})