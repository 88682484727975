<template>
    <div class='header'>
        <div class="icon" @click="change">
            <el-icon v-if="isClose">
                <Expand />
            </el-icon>
            <el-icon v-else>
                <Fold />
            </el-icon>
        </div>
        <div class="right">
            当前用户:{{ authStore.userInfo.company.companyName }}&nbsp;&nbsp;&nbsp;&nbsp;<span
                @click="dialogForm = true">修改密码</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="logout">注销</span>
        </div>
    </div>
    <div class="wrapper">
        <!-- <el-breadcrumb :separator-icon="ArrowRight" style="padding-left: 15px; margin-top: 15px;">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>二级页面</el-breadcrumb-item>
            <el-breadcrumb-item>三级页面</el-breadcrumb-item>
        </el-breadcrumb> -->
        <router-view />
    </div>

    <el-dialog v-model="dialogForm" title="修改密码" width="500">
        <el-form :model="form">
            <el-form-item label="当前密码" :label-width="100">
                <el-input v-model="form.oldpaddword" autocomplete="off" />
            </el-form-item>
            <el-form-item label="新密码" :label-width="100">
                <el-input v-model="form.oldpaddword" autocomplete="off" />
            </el-form-item>
            <el-form-item label="验证新密码" :label-width="100">
                <el-input v-model="form.oldpaddword" autocomplete="off" />
            </el-form-item>
            <el-form-item label="验证码" :label-width="100">
                <el-input v-model="form.oldpaddword" style="width: 100px;" autocomplete="off" />
                <el-button>发送验证码到手机</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogForm = false">关闭</el-button>
                <el-button type="primary" @click="dialogForm = false">
                    修改密码
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { ref, reactive } from 'vue';
    import store from '@/store';
    import router from '@/router/index';
// import { useUserStore } from '@/store/userStore';
    import { useAuthStore } from '@/store/authStore';
    export default{
        props:['isCLose'],
        emits:['change'],
        setup(props,{emit}){
            const authStore = useAuthStore();
            //切换菜单显示
            function change(){
                emit('change')
            }
            //注销
            function logout(){
                authStore.logout();
                //store.commit('logout', { store })
                router.push('/login')
            }

            const form = reactive({
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            })

            const dialogForm = ref(false);
            
            return { change, logout, store, dialogForm, form, authStore }
        }
    }
</script>

<style lang="less" scoped>
    .header{
        height:35px; line-height: 35px;
        background-color:#1e78bf;
        color: white;
        .icon{
            width:10%;
            float: left;
            padding-left: 20px;
            font-size: 24px;
            i{
                cursor: pointer;
            }
        }
        .right{
            width: 80%;
            float: left;
            padding-right: 20px;
            font-size: 16px;
            text-align: right;
            span{
                cursor: pointer;
            }
        }
    }
    .wrapper{
        padding: 5px;
    }
</style>