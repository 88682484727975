import axios from "@/http";
import base from './base'
//用户登录

export const httpLogin =(params) => {
    
    return axios.post(
            base.login.webapi_login,
            params
        );
}
//修改用户
export const modifyUser = (id ,params) =>{
    return axios.put(base.login.webapi_user + "?id=" + id, params)
}
//注册用户
export const reginster = (params) =>{
    return axios.post(base.login.webapi_user, params)
}

